<script setup lang="ts">
import { computed } from "vue";
import { useStore } from "vuex";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

const store = useStore();

const storeError = computed(() => store.state.error);

const showInfo = computed(() => {
  return storeError.value === null ? true : false;
});

const userData = computed(() => store.state.userData!);
const teamsData = computed(() => store.state.teamsData!);
const customerData = computed(() => store.state.customerData!);
const userBatchesData = computed(() => store.state.userBatchesData!);

const showPremiumCard = computed(() => {
  if (userData.value === null) return false;
  if (userData.value.hasActiveSubscription === false) return false;
  if (teamsData.value === null) return false;
  if (teamsData.value.members !== 0) return false;
  if (customerData.value === null) return false;
  return true;
});

const showTeamCard = computed(() => {
  if (userData.value === null) return false;
  if (userData.value.hasActiveSubscription === false) return false;
  if (teamsData.value === null) return false;
  if (teamsData.value.members === 0) return false;
  if (customerData.value === null) return false;
  return true;
});

const showBasicEnterprise = computed(() => {
  if (userData.value === null) return false;
  return !showPremiumCard.value && !showTeamCard.value;
});

const getFormatedTimeZone = (originalTimezone: string) => {
  const timezone = dayjs()
    .tz(originalTimezone)
    .format(`[(GMT]Z[)] [${originalTimezone}]`);
  return timezone;
};
</script>

<template>
  <div v-if="showInfo">
    <div class="card" v-if="userData">
      <div class="flex flex-col">
        <div class="flex">
          <div class="flex-1 m">
            <h2 class="semititle">User Name</h2>
            <p class="userText">{{ userData?.user?.userName }}</p>
          </div>
          <div class="flex-1">
            <h2 class="semititle">Email</h2>
            <p class="userText">{{ userData?.user?.email }}</p>
          </div>
        </div>
        <div class="flex mt-6">
          <div class="flex-1">
            <h2 class="semititle">Timezone</h2>
            <p class="userText">
              {{ getFormatedTimeZone(userData?.timezone) }}
            </p>
          </div>
          <div class="flex-1">
            <h2 class="semititle">Registration Date</h2>
            <p class="userText">{{ userData?.registrationDate }}</p>
          </div>
        </div>
        <div class="flex mt-6">
          <div>
            <h2 class="semititle">Time Since Registration</h2>
            <p class="userText">
              {{ userData?.timeSinceRegistration }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-7" v-if="userData">
      <div class="flex flex-col">
        <h2 class="semititle">Tier</h2>

        <div v-if="showPremiumCard" class="flex flex-col">
          <div class="flex justify-between py-2">
            <p class="flex-1"></p>
            <p class="flex-1 text-brand-gray-500 font-medium text-xs">STATUS</p>
            <p class="flex-1 text-brand-gray-500 font-medium text-xs">PERIOD</p>
          </div>
          <div class="flex justify-between py-2">
            <p class="flex-1 text-brand-gray-700 font-medium text-sm">
              {{ userData?.user?.type }}
            </p>
            <p class="flex-1 text-brand-gray-700 font-medium text-sm">
              {{ customerData?.status }}
            </p>
            <p class="flex-1 text-brand-gray-700 font-medium text-sm">
              {{ customerData?.interval }}
            </p>
          </div>
        </div>
        <div v-if="showTeamCard" class="flex flex-col">
          <div class="flex justify-between py-2">
            <p class="flex-1"></p>
            <p class="flex-1 text-brand-gray-500 font-medium text-xs">STATUS</p>
            <p class="flex-1 text-brand-gray-500 font-medium text-xs">PERIOD</p>
          </div>
          <div class="flex justify-between py-2">
            <p class="flex-1 text-brand-gray-700 font-medium text-sm">
              {{ `Teams (${teamsData?.members} members)` }}
            </p>
            <p class="flex-1 text-brand-gray-700 font-medium text-sm">
              {{ customerData?.status }}
            </p>
            <p class="flex-1 text-brand-gray-700 font-medium text-sm">
              {{ customerData?.interval }}
            </p>
          </div>
        </div>
        <div v-if="showBasicEnterprise">
          <p class="flex-1 text-brand-gray-700 pt-10 font-medium text-sm">
            {{ userData?.user?.type }}
          </p>
        </div>
      </div>
    </div>
    <div class="card mt-7 mb-14" v-if="userBatchesData">
      <div class="flex flex-col">
        <h2 class="semititle">Batches</h2>
        <div class="flex flex-col">
          <div class="flex justify-between py-2">
            <p class="flex-1 text-brand-gray-500 font-medium text-xs">TYPE</p>
            <p class="flex-1 text-brand-gray-500 font-medium text-xs">
              START DATE
            </p>
            <p class="flex-1 text-brand-gray-500 font-medium text-xs">
              END DATE
            </p>
          </div>
          <div v-if="userBatchesData">
            <div
              v-for="batch in userBatchesData"
              class="flex justify-between py-2"
            >
              <p class="flex-1 text-brand-gray-700 font-medium text-sm">
                {{ batch.kind }}
              </p>
              <p class="flex-1 text-brand-gray-700 font-medium text-sm">
                {{ batch.startDate }}
              </p>
              <p class="flex-1 text-brand-gray-700 font-medium text-sm">
                {{ batch.endDate }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
