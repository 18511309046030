import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone"; // dependent on utc plugin

dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

// Functions to process data from API
let timezoneFromAPI: string;

interface UserInfoApiResponse {
  allow_external_benchmarking: boolean;
  batch_done: boolean;
  both_logins: boolean;
  created: string;
  email: string;
  first_log: number;
  first_name: string;
  google_user_id: string;
  is_team_member: boolean;
  is_trial: boolean;
  last_name: string;
  survey_onboarding: boolean;
  timezone: string;
  token_key: string;
  client: { has_active_subscription: boolean; product: string };
}

const getCapitalizeFirstLetter = (name: string) => {
  return name.charAt(0).toUpperCase() + name.slice(1);
};

const getBasicUserInfo = (data: UserInfoApiResponse) => {
  const name = getCapitalizeFirstLetter(data.first_name);
  const lastName = getCapitalizeFirstLetter(data.last_name);
  const userName = `${name} ${lastName}`;
  const email = data.email;
  const typeProduct = data.client.product;
  const type =
    typeProduct === "basic"
      ? "Basic"
      : typeProduct === "enterprise"
      ? "Enterprise"
      : typeProduct === "premium"
      ? "Premium"
      : typeProduct === "teams"
      ? "Teams"
      : null;

  const basicInfo = {
    userName,
    email,
    type,
  };
  return basicInfo;
};

// Get Registration Date - Feb 3, 2022, 10:30 am
const created: number = +"1648208699";

//convert to a date
const getRegistrationDate = (timestamp: string, timezone: string) => {
  return dayjs
    .unix(+timestamp)
    .tz(timezone)
    .format("MMM DD, YYYY, hh:mm a");
};

const getTimeSinceRegistration = (timestamp: string, timezone: string) => {
  const result = dayjs
    .unix(+timestamp)
    .tz(timezone)
    .fromNow(true);
  return result;
};

interface TeamsApiResponse {
  results: Array<{
    members: Array<string>; //number[];
  }>;
}

export function getDataFromTeams(data: TeamsApiResponse) {
  const members = data.results[0].members.length;

  const result = {
    members: members,
  };
  return result;
}

const getActiveSubscription = (data: UserInfoApiResponse) => {
  return data.client.has_active_subscription;
};

interface CustomerApiResponse {
  customer: {
    subscriptions: {
      data: Array<{
        created: number;
        plan: {
          //interval: "month" | "year";
          interval: string;
          active: boolean;
        };
      }>;
    };
  };
}

export function getDataFromCustomer(data: CustomerApiResponse) {
  const activePlan = data.customer.subscriptions.data.find(
    (item) => item.plan.active === true
  );

  let status: string;

  // Enterprise
  if (activePlan) {
    status = "Active";

    const interval =
      activePlan?.plan.interval === "month"
        ? "Montly"
        : activePlan?.plan.interval === "year"
        ? "Yearly"
        : "Response not expected";

    const customer = {
      //type, --> premium, teams, basic, enterprise
      interval,
      status,
    };
    return customer;
  }
}
// Generate type of what is returned from getDataFromUser
export type ParsedUserInfo = ReturnType<typeof getDataFromUser>;

export function getDataFromUser(data: UserInfoApiResponse) {
  timezoneFromAPI = data.timezone;
  const registrationDate = getRegistrationDate(data.created, data.timezone);

  const timeSinceRegistration = getTimeSinceRegistration(
    data.created,
    data.timezone
  );

  const user = getBasicUserInfo(data);

  const hasActiveSubscription = getActiveSubscription(data);

  const result = {
    registrationDate,
    timeSinceRegistration,
    timezone: data.timezone,
    user,
    hasActiveSubscription, //boolean
  };
  return result;
}

// Batches - api/accounts/batches/
function midnightForBatches(date: string) {
  let textForBatches = date
    .toString()
    .split("T")[1]
    .split(":")
    .join("")
    .slice(0, -1);

  textForBatches === "000000" ? (textForBatches = "midnight") : "";
  return textForBatches;
}

// Batches - api/accounts/batches/
function formatDateBatches(date: string, text: string, timezone: string) {
  return text === "midnight"
    ? dayjs(date).tz(timezone).format("MMM DD, YYYY") + " " + text
    : dayjs(date).tz(timezone).format("MMM DD, YYYY, hh:mm a");
}

// Batches - api/accounts/batches/
export function getDataFromBatches(data: any) {
  const batchesList = data.results.map((item: any) => {
    const batches = {
      kind: "",
      startDate: "",
      endDate: "",
    };
    // kind
    item.kind === "onbrd_gap"
      ? (batches.kind = "onboarding gap")
      : (batches.kind = item.kind);

    // Midnight or time -> hh:mm a
    let midnightText = item.start_date
      .toString()
      .split("T")[1]
      .split(":")
      .join("")
      .slice(0, -1);
    midnightText === "000000" ? (batches.startDate = "midnight") : "";

    const textStartDate = midnightForBatches(item.start_date);
    const textEndDate = midnightForBatches(item.end_date);

    batches.startDate = formatDateBatches(
      item.start_date,
      textStartDate,
      timezoneFromAPI
    );
    batches.endDate = formatDateBatches(
      item.end_date,
      textEndDate,
      timezoneFromAPI
    );

    return batches;
  });
  return batchesList;
}
