export async function getDataFromAPI(endpoint: string, userToken: string) {
  const response = await fetch(endpoint, {
    headers: {
      Authorization: `Token ${userToken}`,
    },
    mode: "cors",
  })
    .then((res) => {
      if (res.ok) return res.json();
      if (res.status === 401) {
        return res.status;
      }
    })
    .catch((err) => {
      // Network error
      if (err.status === 502) {
        console.log("Ups! Sorry something wrong with the server!");
      }
    });
  return response;
}
