<script setup lang="ts">
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { ROUTER_NAMES } from "./router";
import { getMonthSpan } from "./services/bqi";
import user from "./assets/images/user.png";
import Error from "./components/Error.vue";

const userToken = ref("");
const currentMonth = ref(new Date().getMonth() + 1); // current month

const currentYear = ref(new Date().getFullYear()); // current year

// Vuex
const store = useStore();
const userError = computed(() => store.state.error);

const onTokenSubmit = async () => {
  await store.dispatch("setUserToken", userToken.value);

  await store.dispatch("fetchUserData");

  if (userError.value === null) {
    if (store.state.userData?.hasActiveSubscription) {
      await store.dispatch("fetchCustomerData");
      await store.dispatch("fetchTeamsData");
    }
    await store.dispatch("fetchUserBatchesData");

    const { start, end, startPrevious, endPrevious } = getMonthSpan(
      currentMonth.value,
      currentYear.value,
      store.state.userData?.timezone
    );
    // set the current
    await store.dispatch("setStartDateSelected", start);

    await store.dispatch("setEndDateSelected", end);

    await store.dispatch("setStartPreviousDate", startPrevious);

    await store.dispatch("setEndPreviousDate", endPrevious);

    await Promise.all([
      store.dispatch("fetchTotalReceivedEmails"),
      store.dispatch("fetchTotalSentEmails"),
      store.dispatch("fetchTotalPreviousReceivedEmails"),
      store.dispatch("fetchTotalPreviousSentEmails"),
      store.dispatch("fetchReceivedEmails"),
      store.dispatch("fetchSentEmails"),
    ]).catch((err) => console.log("Error fetching data from the server", err));
  }
};
</script>

<template>
  <div class="flex justify-center mt-7 mb-11">
    <img
      class="h-12 pt-2 pb-2"
      src="https://uploads-ssl.webflow.com/5d8a317520d8ba54fcd1dfeb/5fd0c5020a7e8635c21b9ad5_EM__logo__wordmark--light.svg"
    />
  </div>
  <Error></Error>
  <div>
    <div class="card mb-5">
      <h1 class="font-semibold text-lg text-black pb-2">
        User Info Specifications
      </h1>
      <p class="text-sm text-brand-gray-700">
        Check user information and metrics entering a token
      </p>
      <form class="flex mt-6 justify-around" @submit.prevent="onTokenSubmit">
        <div>
          <div
            class="bg-white h-8 w-9 flex justify-center items-center absolute border border-brand-gray-400 rounded-l-[3px]"
          >
            <img class="w-4 h-4" :src="user" alt="" />
          </div>
          <input
            placeholder="Add your token"
            class="bg-brand-gray-300 border border-brand-gray-400 rounded-[3px] h-8 w-[350px] py-1 pr-[14px] pl-13 text-[13px] focus:outline-none focus:border-brand-gray-500"
            type="text"
            name="token"
            v-model="userToken"
          />
        </div>
        <button
          class="button rounded-[4px] px-[24.5px] py-[5px] text-sm"
          type="submit"
        >
          See User Metrics
        </button>
      </form>
    </div>
    <div class="flex justify-center py-[6px] px-2 mb-4">
      <router-link
        :to="{ name: ROUTER_NAMES.INFO }"
        class="text-brand-gray-700 text-sm font-semibold px-4 py-1"
        :active-class="'bg-brand text-white rounded-[8px]'"
        >Info</router-link
      >
      <router-link
        :to="{ name: ROUTER_NAMES.METRICS }"
        class="text-brand-gray-700 text-sm font-semibold px-4 py-1"
        :active-class="'bg-brand text-white rounded-[8px]'"
        >Metrics</router-link
      >
    </div>
    <router-view></router-view>
  </div>
</template>
