<script setup lang="ts">
import { useStore } from "vuex";
import { computed, ref } from "@vue/reactivity";
import { watch } from "vue";

const store = useStore();

const storeError = computed(() => store.state.error);

const showError = ref(false);

const setStoreError = computed(() => {
  return storeError.value === null
    ? (showError.value = false)
    : (showError.value = true);
});

watch(setStoreError, async (currentError, oldError) => {
  setTimeout(() => {
    if (currentError) {
      showError.value = false;
    }
  }, 3000);
});
</script>

<template>
  <div v-if="showError">
    <div
      class="absolute top-[55px] right-[56px] w-75 text-center text-error-300 font-semibold bg-error-100 opacity-80 p-2 m-3 rounded-md border-2 border-error"
    >
      <p>Ups! Please enter a valid token!</p>
    </div>
  </div>
</template>
