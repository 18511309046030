import UserInfo from "./pages/UserInfo.vue";
import MetricsInfo from "./pages/MetricsInfo.vue";
import { createWebHistory, createRouter } from "vue-router";

// define routes
// props true --> refer to the params
export const ROUTER_NAMES = {
  INFO: "info",
  METRICS: "metrics",
};
export const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes: [
    { path: "/", redirect: "/info" },
    {
      name: ROUTER_NAMES.INFO,
      path: "/info",
      component: UserInfo,
    },
    { name: ROUTER_NAMES.METRICS, path: "/metrics", component: MetricsInfo },
  ], // short for `routes: routes`
});
