<script setup lang="ts">
import { computed, ref, toRefs } from "vue";

const props = defineProps<{
  title: string;
  legend: string;
  color: Array<string>;
  strokeColor: Array<string>;
  chartData: Array<[number, number]> | null;
}>();

// To have reactive props -> use refs
const { title, legend, chartData, color, strokeColor } = toRefs(props);

// Dynamic options with ref
const options = ref({
  chart: {
    id: "vuechart-example",
    type: "area",
    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    curve: "smooth",
    colors: strokeColor,
    width: 5,
  },
  title: {
    text: title,
    align: "left",
  },
  xaxis: {
    type: "datetime",
    labels: {
      style: {
        fontWeight: 300,
        fontSize: 9,
      },
    },
  },
  grid: {
    show: true,
    borderColor: "#F9F9F9",
  },
  colors: color, // colors for legend
  fill: {
    colors: color,
    opacity: [0.15],
    type: "solid",
  },
  legend: {
    show: true,
    showForSingleSeries: true,
    position: "top",
    horizontalAlign: "left",
    width: 200,
    height: 70,
    fontSize: "14px",
    fontFamily: "Inter, Arial",
    labels: {
      colors: ["#293144"],
    },
  },
  tooltip: {
    x: {
      format: "dd MMM yyyy",
    },
  },
});
const series = computed(() => [
  {
    name: legend.value,
    data: chartData.value ?? [], // coalescing operator
  },
]);
</script>

<template>
  <div>
    <apexchart :options="options" :series="series" height="250px"></apexchart>
  </div>
</template>
