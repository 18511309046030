import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone"; // dependent on utc plugin

dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

// Data --> [[168908490184905, 13], [168908490133905, 11]]
export function transformedHistogramData(
  data: Array<{ unit: string; count: number }>,
  timezone: string
): Array<[number, number]> {
  const histogramDates = data.map((item) => {
    const date = dayjs(item.unit, "YYYYMMDD").tz(timezone).unix() * 1000;

    return [date, item.count] as [number, number];
  });
  return histogramDates;
}

// Get start and end of the month
export function getMonthSpan(
  monthNumber: number,
  yearNumber: number,
  timezone: string
): { start: number; end: number; startPrevious: number; endPrevious: number } {
  const current = dayjs()
    .utc()
    .month(monthNumber - 1) // start at month 0
    .year(yearNumber)
    .startOf("month"); // month selected

  const startMonth = current.utc().unix();

  // Last day of the month is next month minus 1 second
  const next = current.add(1, "month").subtract(1, "second");

  const endMonth = next.unix();

  // Current month minus 1 month
  const previousStartMonth = current.subtract(1, "month").unix();

  // Current month minus 1 second
  const previousEndMonth = current.subtract(1, "second").unix();

  return {
    start: startMonth,
    end: endMonth,
    startPrevious: previousStartMonth,
    endPrevious: previousEndMonth,
  };
}
