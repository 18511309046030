import { createStore } from "vuex";
import { ref } from "vue";
import {
  getDataFromBatches,
  getDataFromCustomer,
  getDataFromTeams,
  getDataFromUser,
  ParsedUserInfo,
} from "../services/backend";
import { backendBaseURL } from "../services/config";
import { getDataFromAPI } from "../services/request";
import { bqiBaseURL } from "../services/config";
import { transformedHistogramData } from "../services/bqi";

const endpointUser = `${backendBaseURL}/accounts/user_info`;
const endpointCustomer = `${backendBaseURL}/billing/customer/`;
const endpointTeams = `${backendBaseURL}/teams/`;
const endpointUserBatches = `${backendBaseURL}/accounts/batches/`;

export default createStore({
  state: {
    userData: null as null | ParsedUserInfo,
    customerData: null,
    teamsData: null,
    userBatchesData: null,
    error: null,
    userToken: null,
    totalSentEmails: null,
    totalReceivedEmails: null,
    totalSentPreviousEmails: null,
    totalReceivedPreviousEmails: null,
    sentEmails: null,
    receivedEmails: null,
    startDateSelected: null,
    endDateSelected: null,
    startPreviousDate: null,
    endPreviousDate: null,
  },

  // Calls to the API
  actions: {
    // After click, store the token from input to -> userToken
    setUserToken(ctx, userToken: string) {
      ctx.commit("setUserToken", userToken);
    },
    // Page - Info
    async fetchUserData(ctx) {
      // Get data from api/accounts/user_info
      const userInfoFromAPI = await getDataFromAPI(
        endpointUser,
        ctx.state.userToken!
      );

      if (userInfoFromAPI === 401) {
        // Get data processed data from api/accounts/user_info
        ctx.commit("setError", `${userInfoFromAPI}`);
        return;
      }

      ctx.commit("setError", null);
      ctx.commit("setUserData", getDataFromUser(userInfoFromAPI));
    },
    // Get data fromapi/billing/customer/
    async fetchCustomerData(ctx) {
      const customerInfoFromAPI = await getDataFromAPI(
        endpointCustomer,
        ctx.state.userToken!
      );
      // Get processed data from api/accounts/user_info
      ctx.commit("setCustomerData", getDataFromCustomer(customerInfoFromAPI));
    },
    // Get data from api/teams
    async fetchTeamsData(ctx) {
      const teamsInfoFromAPI = await getDataFromAPI(
        endpointTeams,
        ctx.state.userToken!
      );
      // Get processed data from api/teams
      ctx.commit("setTeamsData", getDataFromTeams(teamsInfoFromAPI));
    },
    // Get data from api/accounts/batches/
    async fetchUserBatchesData(ctx) {
      const userBatchesFromAPI = await getDataFromAPI(
        endpointUserBatches,
        ctx.state.userToken!
      );
      // Get processed data from api/accounts/batches/
      ctx.commit("setUserBatchesData", getDataFromBatches(userBatchesFromAPI));
    },

    // Page - Metrics
    // After click on date picker in a month, store the 'start' and 'end' values
    // Call mutation to set the new value and send to store
    setStartDateSelected(ctx, startDate: number) {
      ctx.commit("setStartDateSelected", startDate);
    },
    setEndDateSelected(ctx, endDate: number) {
      ctx.commit("setEndDateSelected", endDate);
    },

    setStartPreviousDate(ctx, startDate: number) {
      ctx.commit("setStartPreviousDate", startDate);
    },
    setEndPreviousDate(ctx, endDate: number) {
      ctx.commit("setEndPreviousDate", endDate);
    },

    // Get data from api/total_received_emails
    async fetchTotalReceivedEmails(ctx) {
      const enpointBqiTotalReceivedEmails = `${bqiBaseURL}/total_received_emails?timestamp_from=${ctx.state.startDateSelected}&timestamp_to=${ctx.state.endDateSelected}`;
      const totalReceivedEmailsFromAPI = (await getDataFromAPI(
        enpointBqiTotalReceivedEmails!,
        ctx.state.userToken!
      )) as [{ count: number }];
      // Get the total received emails to Store
      ctx.commit("setTotalReceivedEmails", totalReceivedEmailsFromAPI[0].count);
    },
    async fetchTotalSentEmails(ctx) {
      const endpointBqiTotalSentEmails = `${bqiBaseURL}/total_sent_emails?timestamp_from=${ctx.state.startDateSelected}&timestamp_to=${ctx.state.endDateSelected}`;
      const totalSentEmailsFromAPI = (await getDataFromAPI(
        endpointBqiTotalSentEmails!,
        ctx.state.userToken!
      )) as [{ count: number }];
      //Get the 'totalSentEmails' emails to Store
      ctx.commit("setTotalSentEmails", totalSentEmailsFromAPI[0].count);
    },
    async fetchTotalPreviousReceivedEmails(ctx) {
      const enpointBqiTotalReceivedEmails = `${bqiBaseURL}/total_received_emails?timestamp_from=${ctx.state.startPreviousDate}&timestamp_to=${ctx.state.endPreviousDate}`;
      const totalReceivedPreviousEmailsFromAPI = (await getDataFromAPI(
        enpointBqiTotalReceivedEmails!,
        ctx.state.userToken!
      )) as [{ count: number }];
      // Get the total received emails to Store
      ctx.commit(
        "setTotalReceivedPreviousEmails",
        totalReceivedPreviousEmailsFromAPI[0].count
      );
    },
    async fetchTotalPreviousSentEmails(ctx) {
      const endpointBqiTotalSentEmails = `${bqiBaseURL}/total_sent_emails?timestamp_from=${ctx.state.startPreviousDate}&timestamp_to=${ctx.state.endPreviousDate}`;
      const totalSentPreviousEmailsFromAPI = (await getDataFromAPI(
        endpointBqiTotalSentEmails!,
        ctx.state.userToken!
      )) as [{ count: number }];
      // Set the 'totalSentEmails' emails to Store
      ctx.commit(
        "setTotalPreviousSentEmails",
        totalSentPreviousEmailsFromAPI[0].count
      );
    },
    async fetchReceivedEmails(ctx) {
      const endpointBqiReceivedEmails = `${bqiBaseURL}/received_emails_histogram?timestamp_from=${ctx.state.startDateSelected}&timestamp_to=${ctx.state.endDateSelected}&unit=days`;
      const receivedEmailsFromAPI = await getDataFromAPI(
        endpointBqiReceivedEmails!,
        ctx.state.userToken!
      );

      // Set the 'receivedEmails' emails to Store  update
      ctx.commit(
        "setReceivedEmails",
        transformedHistogramData(
          receivedEmailsFromAPI!,
          // with ! -> I know the type is ParsedUserInfo
          ctx.state.userData!.timezone
        )
      );
    },
    async fetchSentEmails(ctx) {
      const endpointBqiSentEmails = `${bqiBaseURL}/sent_emails_histogram?timestamp_from=${ctx.state.startDateSelected}&timestamp_to=${ctx.state.endDateSelected}&unit=days`;
      const sentEmailsFromAPI = await getDataFromAPI(
        endpointBqiSentEmails!,
        ctx.state.userToken!
      );
      // Set the 'sentEmails' emails to Store  update
      ctx.commit(
        "setSentEmails",
        transformedHistogramData(
          sentEmailsFromAPI,
          ctx.state.userData!.timezone
        )
      );
    },
  },

  mutations: {
    setUserToken(state, payload) {
      state.userToken = payload;
    },
    setUserData(state, payload) {
      state.userData = payload;
    },
    setCustomerData(state, payload) {
      state.customerData = payload;
    },
    setTeamsData(state, payload) {
      state.teamsData = payload;
    },
    setUserBatchesData(state, payload) {
      state.userBatchesData = payload;
    },
    setError(state, payload) {
      state.error = payload;
    },
    // From date picker
    setStartDateSelected(state, payload) {
      state.startDateSelected = payload;
    },
    // From date picker
    setEndDateSelected(state, payload) {
      state.endDateSelected = payload;
    },
    setStartPreviousDate(state, payload) {
      state.startPreviousDate = payload;
    },
    setEndPreviousDate(state, payload) {
      state.endPreviousDate = payload;
    },
    setTotalReceivedEmails(state, payload) {
      state.totalReceivedEmails = payload;
    },
    setTotalSentEmails(state, payload) {
      state.totalSentEmails = payload;
    },
    setTotalReceivedPreviousEmails(state, payload) {
      state.totalReceivedPreviousEmails = payload;
    },
    setTotalPreviousSentEmails(state, payload) {
      state.totalSentPreviousEmails = payload;
    },
    setReceivedEmails(state, payload) {
      state.receivedEmails = payload;
    },
    setSentEmails(state, payload) {
      state.sentEmails = payload;
    },
  },
});
