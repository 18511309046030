<script setup lang="ts">
import { ref, computed } from "vue";
import { useStore } from "vuex";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import chevronR from "../assets/images/chevron-r.png";
import chevronL from "../assets/images/chevron-l.png";
import { getMonthSpan } from "../services/bqi";
import calendar from "../assets/images/calendar.png";

dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

const store = useStore();

// Store must be reactive
const userData = computed(() => store.state.userData!);
const startDayOfMonth = computed(() => store.state.startDateSelected!);
const endDayOfMonth = computed(() => store.state.endDateSelected!);

const months = [
  { text: "January", number: 1, calendar: "December - January" },
  { text: "February", number: 2, calendar: "January - February" },
  { text: "March", number: 3, calendar: "February - March" },
  { text: "April", number: 4, calendar: "March - April" },
  { text: "May", number: 5, calendar: "April - May" },
  { text: "June", number: 6, calendar: "May - June" },
  { text: "July", number: 7, calendar: "June - July" },
  { text: "August", number: 8, calendar: "July - August" },
  { text: "September", number: 9, calendar: "August - September" },
  { text: "October", number: 10, calendar: "September - October" },
  { text: "November", number: 11, calendar: "October - November" },
  { text: "December", number: 12, calendar: "November - December" },
];

// Current month
const currentMonth = ref(new Date().getMonth() + 1);
// Set the month with current month
const selectedMonth = ref(new Date().getMonth() + 1);

const showMonthCalendar = ref(
  months.filter((item) => item.number === selectedMonth.value)[0].calendar
);

// Current Year
const currentYear = ref(new Date().getFullYear());
// Set the current year to show on the
const selectedYear = ref(new Date().getFullYear());

// Show Date Picker when click on the Calendar
const showDatePicker = ref(false);

const handleMonthClick = async (monthNumber: number) => {
  selectedMonth.value = monthNumber;

  const selectedBeforeAfterMonth = months.filter(
    (item) => item.number === monthNumber
  );
  // Show the current month in the calendar
  showMonthCalendar.value = selectedBeforeAfterMonth[0].calendar;

  const { start, end, startPrevious, endPrevious } = getMonthSpan(
    selectedMonth.value,
    selectedYear.value,
    userData?.value.timezone
  );

  // Send to store the values returned from -> getMonthSpan -> start, end, startPrevious, endPrevious
  await store.dispatch("setStartDateSelected", start);

  await store.dispatch("setEndDateSelected", end);

  await store.dispatch("setStartPreviousDate", startPrevious);

  await store.dispatch("setEndPreviousDate", endPrevious);

  // Call BQP API to get data based on the dates selected
  await Promise.all([
    store.dispatch("fetchTotalReceivedEmails"),
    store.dispatch("fetchTotalSentEmails"),
    store.dispatch("fetchTotalPreviousReceivedEmails"),
    store.dispatch("fetchTotalPreviousSentEmails"),
    store.dispatch("fetchReceivedEmails"),
    store.dispatch("fetchSentEmails"),
  ]).catch((err) => console.log("Error fetching data from the server", err));
};
</script>

<template>
  <div class="flex justify-center">
    <button
      class="card-calendar flex justify-between items-center mb-4"
      @click="showDatePicker = !showDatePicker"
    >
      <p class="text-brand-black">{{ showMonthCalendar }}</p>
      <img class="w-4 h-4" :src="calendar" alt="" />
    </button>
  </div>
  <div v-if="showDatePicker" class="flex justify-center">
    <div class="card-date-picker w-[312px] absolute z-10 mt-[-6px]">
      <div class="flex justify-between py-3">
        <button
          class="p-[7px] border-[1px] border-brand-gray-400 rounded-[3px]"
        >
          <img :src="chevronL" @click="selectedYear -= 1" alt="arrow left" />
        </button>
        <h1 class="text-[15px] text-brand-gray-900 font-medium">
          {{ selectedYear }}
        </h1>
        <button
          class="p-[7px] border-[1px] border-brand-gray-400 rounded-[3px]"
        >
          <img :src="chevronR" @click="selectedYear += 1" alt="arrow right" />
        </button>
      </div>
      <div
        class="flex flex-col flex-wrap w-[287px] h-[164px] justify-between content-between"
      >
        <button
          class="button-month hover:bg-brand hover:text-white disabled:bg-gray-100 disabled:opacity-75 disabled:text-gray-400"
          v-for="month in months"
          :class="[month.number === selectedMonth ? 'button-selected' : '']"
          @click="handleMonthClick(month.number)"
          :disabled="
            (month.number > currentMonth && selectedYear >= currentYear) ||
            selectedYear > currentYear
          "
        >
          {{ month.text }}
        </button>
      </div>
    </div>
  </div>
</template>
