import { createApp } from "vue";
import App from "./App.vue";
import "./index.css";
import { router } from "./router";
import store from "./store";
import VueApexCharts from "vue3-apexcharts";
const app = createApp(App); //dayjs
// Create a new store instance.
app.use(store);
app.use(router);
app.use(VueApexCharts);

// app.config.globalProperties.$apexcharts = VueApexCharts;

// // Add this when into a TypeScript codebase
// declare module "@vue/runtime-core" {
//   interface ComponentCustomProperties {
//     $apexcharts: typeof ApexCharts;
//   }
// }
app.mount("#app");
