<script setup lang="ts">
import { ref } from "vue";
import { useStore } from "vuex";
import { computed } from "@vue/reactivity";
import arrowDown from "../assets/images/arrow-down.png";
import arrowUp from "../assets/images/arrow-up.png";
import AreaChart from "../components/AreaChart.vue";
import DatePicker from "../components/DatePicker.vue";

const titleForSent = ref("Sent messages by day");
const titleForReceived = ref("Received messages by day");

const colorForReceived = ref(["#418FFB", "#418FFB"]);
const colorForSent = ref(["#39E396", "#39E396"]);
const strokeColorReceived = ref(["#418ffb80", "#418ffb80"]);
const strokeColorSent = ref(["#39e39680", "#39e39680"]);
const legendForReceived = ref("Received messages");
const legendForSent = ref("Sent messages");

const store = useStore();

const storeError = computed(() => store.state.error);

const showMetrics = computed(() => {
  return storeError.value === null ? true : false;
});

const percentageEmails = (current: number, previous: number) => {
  let difference = current - previous;
  let average = (current + previous) / 2;
  let percentage = Math.round((difference / average) * 100);
  return percentage;
};

const percentageReceived = computed(() => {
  return percentageEmails(
    store.state.totalReceivedEmails,
    store.state.totalReceivedPreviousEmails
  );
});

const percentageSent = computed(() => {
  return percentageEmails(
    store.state.totalSentEmails,
    store.state.totalSentPreviousEmails
  );
});

const userData = computed(() => store.state.userData!);

const receivedEmails = computed(() => store.state.receivedEmails);

const sentEmails = computed(() => store.state.sentEmails);

const totalReceivedEmails = computed(() => store.state.totalReceivedEmails);

const totalSentEmails = computed(() => store.state.totalSentEmails);

const totalReceivedPreviousEmails = computed(
  () => store.state.totalReceivedPreviousEmails
);

const totalSentPreviousEmails = computed(
  () => store.state.totalSentPreviousEmails
);
</script>

<template>
  <div v-if="showMetrics">
    <div v-if="userData">
      <DatePicker></DatePicker>
      <div class="flex justify-between">
        <div class="card-sm flex flex-col items-center">
          <h1 class="font-semibold text-lg text-brand-base pt-3 pb-1">
            Sent Messages
          </h1>
          <div class="flex justify-center">
            <h1 class="font-semibold text-[22.37px] text-brand-gray-800 pr-2">
              {{ totalSentEmails }}
            </h1>
            <div
              :class="percentageSent > 0 ? 'bg-success-200 ' : 'bg-error-100'"
              class="flex rounded-[17.9px] self-center p-1"
            >
              <img :src="percentageSent > 0 ? arrowUp : arrowDown" alt="" />
              <p
                :class="
                  percentageSent > 0 ? 'text-success-300' : 'text-error-300'
                "
                class="font-semibold text-[8.95px] px-1"
              >
                {{ percentageSent }}%
              </p>
            </div>
          </div>
          <p class="font-medium text-[8.95px] text-brand-gray-500 py-1">
            from {{ totalSentPreviousEmails }}
          </p>
        </div>
        <div class="card-sm flex flex-col items-center">
          <h1 class="font-semibold text-lg text-brand-base pt-3 pb-1">
            Received Messages
          </h1>
          <div class="flex justify-center">
            <h1 class="font-semibold text-[22.37px] text-brand-gray-800 pr-2">
              {{ totalReceivedEmails }}
            </h1>
            <div
              :class="
                percentageReceived > 0 ? 'bg-success-200 ' : 'bg-error-100'
              "
              class="flex rounded-[17.9px] self-center p-1"
            >
              <img :src="percentageReceived > 0 ? arrowUp : arrowDown" alt="" />
              <p
                :class="
                  percentageReceived > 0 ? 'text-success-300' : 'text-error-300'
                "
                class="font-semibold text-[8.95px] px-1"
              >
                {{ percentageReceived }}%
              </p>
            </div>
          </div>
          <p class="font-medium text-[8.95px] text-brand-gray-500 py-1">
            from {{ totalReceivedPreviousEmails }}
          </p>
        </div>
      </div>
      <div class="flex flex-col mt-2">
        <div class="card my-2">
          <AreaChart
            :title="titleForSent"
            :chart-data="sentEmails"
            :color="colorForSent"
            :strokeColor="strokeColorSent"
            :legend="legendForSent"
          ></AreaChart>
        </div>
        <div class="card my-2">
          <AreaChart
            :title="titleForReceived"
            :chart-data="receivedEmails"
            :color="colorForReceived"
            :strokeColor="strokeColorReceived"
            :legend="legendForReceived"
          ></AreaChart>
        </div>
      </div>
    </div>
  </div>
</template>
